// Core modules
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

// Third-party modules
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

// Internal modules
import {environment} from '@env/environment';

// Internal models
import {PollResponse} from '@app/shared/models/poll_response';
import {Answer} from '@app/shared/models/answer';
import {Question} from '@app/shared/models/question';

@Injectable()
export class PollService {

    /**
     * Data members
     */
    answers: any;

    /**
     * @function constructor
     * @param {HttpClient} http
     */
    constructor(
        private http: HttpClient
    ) {}

    /**
     * @function getQuestionData
     * @description
     * @public
     * @param {number} questionId
     * @returns {Observable<Question>}
     */
    public getQuestionData(questionId: number): Observable<Question> {
        return this.http.get(environment.serverUrl + '/voteservice/question/' + questionId)
            .pipe(
                map((res) => res['Question'])
            );
    }

    /**
     * @function postResponse
     * @description
     * @public
     * @param {PollResponse} pollResponse
     * @param {number} surveyID
     * @returns {Observable<Answer>}
     */
    public postResponse(pollResponse: PollResponse, surveyID: number): Observable<Answer> {
        const body = new HttpParams()
            .set('suerveyid', JSON.stringify(surveyID))
            .set('value', JSON.stringify(pollResponse.value));
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            })
        };

        return this.http.post(environment.serverUrl + '/voteservice/survey/' + surveyID + '/newanswers', body, httpOptions)
            .pipe(
                map((data: any) => {
                    return data['Answers'];
                })
            );
    }

}
