// Core modules
import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

// Third-party modules
import {TranslateService} from '@ngx-translate/core';
import {merge} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';

// Internal modules
import {environment} from '@env/environment';

// Internal services
import {I18nService} from '@app/core/i18n.service';
import {Logger} from '@app/core/logger.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {CustoService} from '@app/shared/service/custo.service';

// Global variables declaration
const logger = new Logger('AppComponent');

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    /**
     * @function constructor
     * @param {Router} router
     * @param {ActivatedRoute} activatedRoute
     * @param {Title} titleService
     * @param {TranslateService} translateService
     * @param {I18nService} i18nService
     * @param {BrowserService} _browserService
     */
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private translateService: TranslateService,
        private i18nService: I18nService,
        private _browserService: BrowserService,
        private _custoService: CustoService
    ) {
        this._updateDeviceClassnames();
    }

    /**
     * @function onResize
     * @description
     * @public
     * @returns {void}
     */
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this._updateDeviceClassnames();
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        if (environment.production) {
            Logger.enableProductionMode();
        }
        this.i18nService.init();

        // Change page title on navigation or language change, based on route data
        const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
        merge(this.translateService.onLangChange, onNavigationEnd)
            .pipe(
                map(() => {
                    let route = this.activatedRoute;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe(event => {
                const title = event['title'];
                if (title) {
                    this.titleService.setTitle(this.translateService.instant(title));
                }
            });
    }

    /**
     * @function _updateDeviceClassnames
     * @description
     * @private
     * @returns {void}
     */
    private _updateDeviceClassnames() {
        const classlist = document.body.classList;
        if (this._browserService.isMobile() || (!this._browserService.isMobile() && document.body.clientWidth <= 823)) {
            classlist.add('mobile');
            classlist.remove('desktop');
        } else if (!this._browserService.isMobile() && document.body.clientWidth > 823) {
            classlist.remove('mobile');
            classlist.add('desktop');
        }
    }

}
