// Core modules
import {Injectable} from '@angular/core';

// Third-party modules
import {HttpClient} from '@angular/common/http';
import {Observable, Subject, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

// Internal modules
import {environment} from '@env/environment';

// Internal models
import {User, UserRole} from '@app/shared/models/user';

// Internal services
import {UserService} from '@app/shared/service/user.service';
import {BrowserService} from '@app/shared/service/browser.service';

// Global variables declaration
const credentialsKey = 'credentials';

export interface Credentials {
    username: string;
    connection_id: string;
    token_type: string;
    access_token: string;
    tenant: string;
    refresh_token: string;
    expires_in: number;
    user: User;
}

export interface LoginContext {
    username: string;
    password: string;
    remember?: boolean;
}

export enum ErrorMessage {
    DUAL_LOGIN = 'dual-login'
}

@Injectable()
export class AuthenticationService {

    /**
     * Data members
     */
    private _credentials: Credentials | null;
    private _logoutSubject: Subject<void> = new Subject();
    private _errorSubject: Subject<string> = new Subject();
    private _isExternalUser: boolean;
    readonly _browserInfo: string;

    /**
     * @function constructor
     * @param httpClient
     * @param userService
     * @param _browserService
     */
    constructor(
        private httpClient: HttpClient,
        private userService: UserService,
        private _browserService: BrowserService
    ) {
        this._isExternalUser = null;
        this._browserInfo = this._getBrowserInfoStr();
        const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
        if (savedCredentials) {
            this._credentials = JSON.parse(savedCredentials);
        }
    }

    /**
     * @function login
     * @description Authenticates the user.
     * @public
     * @param {LoginContext} context
     * @returns {Observable<Credentials>}
     */
    public login(context: LoginContext): Observable<User> {
        const data = {
            uid: context.username,
            password: context.password.trim(),
            client: environment.authClientId
        };

        return this.httpClient.post(
            environment.authUrl, data
        ).pipe(
            mergeMap((credentials: Credentials) => {
                this.setCredentials(credentials);

                return this.userService.me().pipe(
                    map((user: User) => {
                        const date = new Date();
                        const time = (date.getTime() / 1000 + credentials.expires_in);
                        const rand = Math.floor((Math.random() * 9999999) + 1000000);
                        const exploded = user.uid.split('@');
                        if (user.roles_bo.indexOf(UserRole.JOIN_AUTO) >= 0) {
                            credentials.connection_id = user.uid + '/' + this._browserInfo + '-' + (Math.floor(Date.now() / 1000)) + ':' + rand;
                        } else {
                            credentials.connection_id = user.uid + '/' + this._browserInfo + '-' + (Math.floor(Date.now() / 1000)) + ':' + rand;
                        }
                        credentials.username = user.uid.toLowerCase();
                        credentials.expires_in = Math.floor(time);
                        credentials.user = user;
                        if (exploded.length > 1) {
                            credentials.tenant = exploded[1];
                        }
                        this.setCredentials(credentials);
                        return user;
                    })
                );
            })
        );
    }

    /**
     * @function _getBrowserInfoStr
     * @description
     * @private
     * @returns {string}
     */
    private _getBrowserInfoStr(): string {
        const info: any = this._browserService.getAll();
        const platform = info.platform.type ? info.platform.type : '';
        const os = info.os.name ? (info.os.name + (info.os.version ? '-' + info.os.version : '')) : '';
        const browser = info.browser.name ? (info.browser.name + (info.browser.version ? '-' + info.browser.version : '')) : '';
        return '(' + platform + '|' + os + '|' + browser + ')';
    }

    /**
     * @function logout
     * @description
     * @public
     * @returns {Observable<boolean>}
     */
    public logout(): Observable<boolean> {
        this._isExternalUser = null;
        this.logoutSubject.next();
        return of(true);
    }

    /**
     * @function isAuthenticated
     * @description
     * @public
     * @returns {boolean}
     */
    public isAuthenticated(): boolean {
        if (!this.credentials) {
            return false;
        }
        const time = new Date().getTime();
        return (time / 1000) < this.credentials.expires_in;
    }

    /**
     * @function credentials
     * @description
     * @public
     * @returns {Credentials|null}
     */
    get credentials(): Credentials | null {
        return this._credentials;
    }

    /**
     * @function setCredentials
     * @description
     * @public
     * @param {Credentials=} credentials
     * @param {boolean=} remember
     * @returns {void}
     */
    public setCredentials(credentials?: Credentials, remember?: boolean): void {
        if (credentials) {
            this._credentials = credentials;
        }

        if (credentials) {
            const storage = remember ? localStorage : sessionStorage;
            storage.setItem(credentialsKey, JSON.stringify(credentials));
        } else {
            sessionStorage.removeItem(credentialsKey);
            localStorage.removeItem(credentialsKey);
        }
    }

    /**
     * @function isExternalUser
     * @description
     * @public
     * @returns {Promise<boolean>}
     */
    public isExternalUser(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let bool: boolean;
            if (typeof this._isExternalUser === 'object' && this._isExternalUser === null) {
                this.userService.me().subscribe(
                    (user: User) => {
                        this._isExternalUser = bool = user.roles_bo.indexOf(UserRole.JOIN_AUTO) >= 0; // typeof this._isExternalUser is boolean now
                        resolve(bool);
                    },
                    () => {
                        reject();
                    });
            } else {
                resolve(this._isExternalUser);
            }
        });
    }

    /**
     * @function logoutSubject
     * @description
     * @public
     * @returns {Subject<boolean>}
     */
    get logoutSubject(): Subject<void> {
        return this._logoutSubject;
    }

    /**
     * @function errorSubject
     * @description
     * @public
     * @returns {Subject<string>}
     */
    get errorSubject(): Subject<string> {
        return this._errorSubject;
    }

}
