import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OpentokNetworkTestService} from '@app/home/pre-call-test-tool/opentok-network-test.service';
import {OpentokService} from '@app/home/session/conference/opentok.service';
import {Device, Publisher} from '@opentok/client';
import {SessionService} from '@app/shared/service/session.service';
import {Subscription} from 'rxjs';
import {PreCallTestStatus} from '@app/shared/models/pre-call-test-status';
import {Session} from '@app/shared/models/session';
import {ErrorNames} from 'opentok-network-test-js';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-pre-call-test-tool',
    templateUrl: './pre-call-test-tool.component.html',
    styleUrls: ['./pre-call-test-tool.component.scss']
})
export class PreCallTestToolComponent implements OnInit, OnDestroy {
    @ViewChild('cameraOutput') cameraOutput: ElementRef;
    public mediaStream: MediaStream;
    public microLevel: number;
    public testStatuses = PreCallTestStatus;
    public testStatus: PreCallTestStatus = PreCallTestStatus.IDLE;
    public connectivityTestProgressStats: number;
    public connectivityTestProgressWidth: number;
    public audioDevices: Device[];
    public videoDevices: Device[];
    public selectedAudio: Device;
    public selectedVideo: Device;
    public qualityResult: string;
    public errorMessage: string;

    private publisher: Publisher;
    private connectivityTestAttempt: number;
    private sub: Subscription;
    private session: Session;
    private constraints: MediaStreamConstraints;

    constructor(private router: Router,
                private otNetworkTestService: OpentokNetworkTestService,
                private opentokService: OpentokService,
                private sessionService: SessionService,
                private translateService: TranslateService) {
        this.connectivityTestProgressWidth = 335;
    }

    async ngOnInit() {
        this.publisher = this.opentokService.getOT().initPublisher(this.cameraOutput.nativeElement, {
            showControls: false,
            audioFallbackEnabled: true
        });
        this.opentokService.getOT().getDevices(async (err: unknown, devices: Device[]) => {
            if (err) {
                this.errorMessage = this.parseErrorMessage({name: ErrorNames.FAILED_TO_OBTAIN_MEDIA_DEVICES});
                return;
            }

            await this.setDefaultDevices(devices);
        });

        this.sub = this.sessionService.sessionList.subscribe(async (sessionList) => {
            if (sessionList && sessionList[0]) {
                this.session = sessionList[0];
            }
        });

        this.publisher.on('audioLevelUpdated', (e) => {
            this.microLevel = e.audioLevel * 1000;
        });

    }

    ngOnDestroy() {
        this.publisher.off('audioLevelUpdated');
        this.publisher.destroy();
        this.destroyMediaStream();
        this.sub.unsubscribe();
    }

    public async startConnectivityTest(): Promise<void> {
        if (!this.session) {
            return;
        }

        this.qualityResult = '';
        this.errorMessage = '';
        this.connectivityTestProgressStats = 0;
        this.testStatus = this.testStatuses.IN_PROG;

        await this.otNetworkTestService.openTestConnection(this.session);
        const connectionEstablished = await this.otNetworkTestService.testConnectivity();

        if (!connectionEstablished.success) {
            this.errorMessage = this.parseErrorMessage(connectionEstablished);
            this.testStatus = this.testStatuses.ERROR;
            return;
        }

        this.connectivityTestAttempt = 0;
        this.otNetworkTestService.testQuality((stats) => this.connectivityTestProgress())
            .then((result) => {
                this.connectivityTestProgressStats = this.connectivityTestProgressWidth;
                setTimeout(() => {
                    this.testStatus = this.testStatuses.FINISHED;
                }, 500);
                this.qualityResult = this.getTestResultMessage(result);
            })
            .catch(err => {
                this.parseErrorMessage(connectionEstablished);
                this.testStatus = this.testStatuses.ERROR;
            })
    }

    public async deviceChanged(device: Device, type: 'audio' | 'video'): Promise<void> {
        if (type === 'audio') {
            this.selectedAudio = device;
            this.constraints.audio = {deviceId: this.selectedAudio.deviceId ? {exact: this.selectedAudio.deviceId} : undefined};
            const newStream = await this.updateUserMedia()
            const audioTrack = newStream.getAudioTracks().find(t => t.enabled);
            this.publisher.setAudioSource(this.selectedAudio.deviceId);
        } else {
            this.selectedVideo = device;
            this.constraints.video = {deviceId: this.selectedVideo.deviceId ? {exact: this.selectedVideo.deviceId} : undefined};
            this.updateUserMedia();
        }
    }

    public disableDevice(status: boolean, type: 'audio' | 'video'): void {
        if (type === 'audio') {
            this.publisher.publishAudio(status);
        } else {
            this.otNetworkTestService.connectionOptions.audioOnly = !status;
            this.publisher.publishVideo(status);

        }
    }

    public close(): void {
        this.router.navigate(['sessions']);
    }

    private setDefaultDevices(devices: Device[]): Promise<MediaStream> {
        this.audioDevices = devices.filter(d => d.kind === 'audioInput');
        this.videoDevices = devices.filter(d => d.kind === 'videoInput');
        this.selectedAudio = this.audioDevices && this.audioDevices[0]
        this.selectedVideo = this.videoDevices && this.videoDevices[0];

        this.constraints = {
            audio: {deviceId: this.selectedAudio.deviceId ? {exact: this.selectedAudio.deviceId} : undefined},
            video: {deviceId: this.selectedVideo.deviceId ? {exact: this.selectedVideo.deviceId} : undefined}
        }

        return this.updateUserMedia();
    }

    private updateUserMedia(): Promise<MediaStream> {
        return navigator.mediaDevices.getUserMedia(this.constraints)
            .then(stream => {
                this.mediaStream = stream;
                return stream;
            });
    }

    private destroyMediaStream(): void {
        this.mediaStream.getTracks().forEach(track => track.stop());
    }

    private connectivityTestProgress(): void {
        const maximumAttempts = this.otNetworkTestService.connectionOptions.timeout / 1000;
        const progressStep = this.connectivityTestProgressWidth / 100;
        this.connectivityTestProgressStats = Math.floor(++this.connectivityTestAttempt / maximumAttempts * 100 * progressStep);
    }

    private getTestResultMessage(result: number): string {
        if (result < 1.69) {
            return this.translateService.instant('Your bandwidth is too low for video or audio.');
        }

        if (result < 2.39) {
            return this.translateService.instant('Poor Connection. Try to use audio only.');
        }

        if (result < 3.79) {
            return this.translateService.instant('Good Connection!');
        }

        if (result > 3.8) {
            return this.translateService.instant('Excellent Connection! You are all set!');
        }
    }

    private parseErrorMessage(error: any): string {
        switch (error.name) {
            case ErrorNames.API_CONNECTIVITY_ERROR:
                return this.translateService.instant('Failed to connect to OpenTOK API Server');
            case ErrorNames.FAILED_TO_OBTAIN_MEDIA_DEVICES:
                return this.translateService.instant('Failed to obtain media devices');
            case ErrorNames.NO_AUDIO_CAPTURE_DEVICES:
                return this.translateService.instant('Cannot access a microphone');
            case ErrorNames.NO_VIDEO_CAPTURE_DEVICES:
                return this.translateService.instant('Cannot access a camera');
            case ErrorNames.UNSUPPORTED_BROWSER:
                return this.translateService.instant('Unsupported browser is being used');
            case ErrorNames.SUBSCRIBER_GET_STATS_ERROR:
                return this.translateService.instant('Failed to get audio and video statistics');
            default:
                return this.translateService.instant('Failed to test the connectivity');
        }
    }

}
